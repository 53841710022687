import { defineModule, localActionContext } from 'direct-vuex';
import {
	AutoFactory,
	DemographicInformationAssociations,
	DemographicInformationAssociationsService,
	OnlinePortalRegulation1071StatusService,
	EmailLog,
	OnlinePortalRegulation1071EmailService
} from '@sageworks/jpi';
import { fetchCustomerByIds } from '@/logic/Customer.service';
import { createCustomerEmailObject } from '@/utils/DemographicInformationUtils';

// eslint-disable-next-line no-use-before-define
const actionContext = (context: any) => localActionContext(context, DemographicFormModule);

export interface DemographicInformationFormModuleState {
	ownersRequiringDemographicData: DemographicInformationAssociations[];
	statusByCustomerIds: { [key: number]: string };
	emailsLogs: EmailLog[];
	demographic1071FormsStatus: boolean | null;
	emailsByCustomerIds: { [key: number]: string };
}

const DemographicFormModule = defineModule({
	namespaced: true,
	state: () => {
		return {
			ownersRequiringDemographicData: [],
			statusByCustomerIds: {},
			emailsLogs: [],
			demographic1071FormsStatus: null,
			emailsByCustomerIds: {}
		} as DemographicInformationFormModuleState;
	},
	mutations: {
		SET_ENTITIES_THAT_REQUIRE_DEMOGRAPHIC_DATA(state, demographicFormLinks: DemographicInformationAssociations[]) {
			state.ownersRequiringDemographicData = demographicFormLinks;
		},
		SET_STATUS_FOR_DEMOGRAPHIC_FORMS(state, statusByCustomerIds: { [key: number]: string }) {
			state.statusByCustomerIds = statusByCustomerIds;
		},
		SET_LOGS_FOR_DEMOGRAPHIC_FORMS(state, logsByCustomerIds: EmailLog[]) {
			state.emailsLogs = logsByCustomerIds;
		},
		SET_STATUS_FOR_All_FORMS(state, demographic1071FormsStatus: boolean) {
			state.demographic1071FormsStatus = demographic1071FormsStatus;
		},
		SET_EMAILS_FOR_ENTITIES(state, emailsByCustomerIds: { [key: number]: string }) {
			state.emailsByCustomerIds = emailsByCustomerIds;
		}
	},
	actions: {
		async loadOwnersThatNeedForms(context, { loanApplicationId }: { loanApplicationId: number }) {
			const { commit } = actionContext(context);
			const demographicService = AutoFactory.get(DemographicInformationAssociationsService);
			const entitiesThatRequireDemographicData = await demographicService.getEntitiesRequiringDemographicInformation(loanApplicationId);
			commit.SET_ENTITIES_THAT_REQUIRE_DEMOGRAPHIC_DATA(entitiesThatRequireDemographicData);
		},
		async loadFormsStatus(context, { loanApplicationId }: { loanApplicationId: number }) {
			const { commit } = actionContext(context);
			const statusService = AutoFactory.get(OnlinePortalRegulation1071StatusService);
			const status = await statusService.getStatusesForLoanApplication(loanApplicationId);
			commit.SET_STATUS_FOR_DEMOGRAPHIC_FORMS(status);
		},
		async loadFormLogs(context, { loanApplicationId }: { loanApplicationId: number }) {
			const { commit } = actionContext(context);
			const logsService = AutoFactory.get(OnlinePortalRegulation1071EmailService);
			const logs = await logsService.getLogs(loanApplicationId);
			commit.SET_LOGS_FOR_DEMOGRAPHIC_FORMS(logs);
		},
		async loadAll1071FormsValidForSubmit(context, { loanApplicationId }: { loanApplicationId: number }) {
			const { commit } = actionContext(context);
			const logsService = AutoFactory.get(OnlinePortalRegulation1071StatusService);
			const demographic1071FormsStatus = await logsService.demographic1071FormsAreComplete(loanApplicationId);
			commit.SET_STATUS_FOR_All_FORMS(demographic1071FormsStatus);
		},
		async loadCustomerEmail(context, { customersIds }: { customersIds: number[] }) {
			if (customersIds.length === 0) {
				return;
			}
			const { commit } = actionContext(context);
			const customers = await fetchCustomerByIds(customersIds);
			const emails = createCustomerEmailObject(customers);
			commit.SET_EMAILS_FOR_ENTITIES(emails);
		}
	}
});

export default DemographicFormModule;
