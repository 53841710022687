
import Vue, { PropType } from 'vue';
import TermsAndConditions from '../../components/terms-and-conditions/TermsAndConditions.vue';
import SubmitEsign from './submit-esign.vue';
import moment from 'moment';

export default Vue.extend({
	name: 'SubmitBody',
	components: {
		TermsAndConditions,
		SubmitEsign
	},
	props: {
		itemLabel: {
			type: String as PropType<string | null>,
			default: null
		},
		submittedDate: {
			type: Date as PropType<Date | null>,
			default: null
		},
		currentUserIsLender: {
			type: Boolean as PropType<boolean | null>,
			default: null
		},
		canSubmitApplicationWithoutESignatures: {
			type: Boolean as PropType<boolean | null>,
			default: null
		},
		isSubmitting: {
			type: Boolean as PropType<boolean | null>,
			default: null
		},
		isValidating: {
			type: Boolean as PropType<boolean | null>,
			default: null
		},
		allRecipientsHaveSignedAgreement: {
			type: Boolean as PropType<boolean | null>,
			default: null
		},
		adobeAgreementCreated: {
			type: Boolean as PropType<boolean | null>,
			default: null
		},
		customerCanUseEsign: {
			type: Boolean as PropType<boolean | null>,
			default: null
		},
		applicationIdAsNumber: {
			type: Number as PropType<number | null>,
			default: null
		},
		preSubmitMessage: {
			type: String as PropType<string | null>,
			default: null
		},
		postSubmitMessage: {
			type: String as PropType<string | null>,
			default: null
		}
	},
	data() {
		return {
			internalTermsAccepted: false,
			loading: false
		};
	},
	computed: {
		submittedDateText(): string {
			const date = this.submittedDate;
			if (!date) {
				return '';
			}
			return moment(date).format('MM/DD/YYYY');
		},
		isValidatingOrSubmitting(): boolean {
			return this.isValidating || false || this.isSubmitting || false;
		}
	},
	mounted() {},
	methods: {
		showRecreateSignatureModal() {
			this.$emit('showRecreateSignatureModal');
		},
		submitApplicationWithoutESignatures() {
			this.$emit('submitApplicationWithoutESignatures');
		},
		submitApplication() {
			this.$emit('submitApplication');
		},
		sendEmailsForSignatures() {
			this.$emit('sendEmailsForSignatures');
		}
	}
});
