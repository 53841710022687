
import Vue, { PropType } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { AnonymousBorrowerMinimumAccessService, AutoFactory, Permission, DemographicInformationAssociations, EmailLog } from '@sageworks/jpi';
import SidebarDrawer from '../../../components/sidebar-drawer/sidebar-drawer.vue';
import { mapGetters } from 'vuex';
import { formatUrl } from '../../../utils/DemographicInformationUtils';
import DemographicStatusBadge from './demographic-forms-status-badge.vue';
import SendDemographicRequestPopup from './send-demographic-request-popup.vue';

export default Vue.extend({
	name: 'DemographicForm1071',
	components: {
		SendDemographicRequestPopup,
		DemographicStatusBadge,
		SidebarDrawer,
		FontAwesomeIcon
	},
	props: {
		applicationId: {
			type: Number,
			required: true
		},
		customer: {
			type: Object as PropType<DemographicInformationAssociations>,
			required: true
		}
	},
	data() {
		return {
			isDrawerOpen: false,
			loadingDemographicFormUrl: false,
			origin: window.location.origin,
			demographicFormUrl: undefined as string | undefined,
			isRequestPopupOpen: false
		};
	},
	computed: {
		...mapGetters('InstitutionSettings', ['allowBorrowerFillOut1071DemographicFormForAllOwners']),
		...mapGetters('User', ['isLender']),
		emailLogs(): EmailLog[] {
			return this.directStore.state.DemographicForm.emailsLogs;
		},
		customerEmail(): string {
			const emails = this.directStore.state.DemographicForm.emailsByCustomerIds;
			if (!this.customer.customerId) {
				return '';
			}
			return emails[this.customer.customerId] || '';
		},
		demographicFormStatus(): { [key: number]: string } {
			return this.directStore.state.DemographicForm.statusByCustomerIds;
		},
		allowTheUserToEnterDemographicInformation(): boolean {
			return this.directStore.getters.LenderAuthorizationModule.canAccessFeature(Permission.ProductFeatureEnum.AllowTheUserToEnterDemographicInformation);
		},
		allowTheUserToSendDemographicRequest(): boolean {
			return this.directStore.getters.LenderAuthorizationModule.canAccessFeature(Permission.ProductFeatureEnum.AllowTheUserToSendDemographicRequest);
		},
		showSendEmailRequest(): boolean {
			return this.isLender && this.allowTheUserToSendDemographicRequest;
		}
	},
	watch: {
		// Added this because the emails were not being set in the popup after they were fetch in the onBeforeMount
		customerEmail() {
			this.$nextTick(() => {
				this.$refs[`requestPopup-${this.customer.customerId}`].userEmail = this.customerEmail;
			});
		}
	},
	async beforeMount(): Promise<void> {
		const dataLoadPromises: Promise<any>[] = [
			this.directStore.dispatch.DemographicForm.loadFormsStatus({ loanApplicationId: this.applicationId }),
			this.directStore.dispatch.DemographicForm.loadFormLogs({ loanApplicationId: this.applicationId }),
			this.directStore.dispatch.LenderAuthorizationModule.loadUserPermissions()
		];
		await Promise.all(dataLoadPromises);

		const customerIdsForEmails = this.directStore.state.DemographicForm.ownersRequiringDemographicData
			.map(owner => owner.customerId)
			.filter((id): id is number => id !== undefined);
		await this.directStore.dispatch.DemographicForm.loadCustomerEmail({ customersIds: customerIdsForEmails });
	},
	async mounted() {
		window.addEventListener('message', this.handleFormSubmitted);
	},
	destroyed() {
		window.removeEventListener('message', this.handleFormSubmitted);
	},
	methods: {
		getLogsForCustomer(customerId: number): EmailLog[] {
			return this.emailLogs.filter(log => log.customerId === customerId);
		},
		openModal() {
			this.isRequestPopupOpen = true;
		},
		closeModal() {
			this.isRequestPopupOpen = false;
		},
		hideDrawer() {
			this.isDrawerOpen = false;
		},
		openDrawer() {
			this.isDrawerOpen = true;
		},
		showEnterDemographicInfoButton(customerId: number): boolean {
			if (this.isLender) {
				return this.allowTheUserToEnterDemographicInformation;
			}
			return this.allowBorrowerFillOut1071DemographicFormForAllOwners && !this.formIsCompleted(customerId);
		},
		handleFormSubmitted({ origin, data }: MessageEvent<{ canceled: boolean; success: boolean; eventType: string }>) {
			if (!this.demographicFormUrl) {
				return;
			}

			// Ensure the event contains the correct origin so we know it came from the nested iframe
			const expectedOrigin = new URL(this.demographicFormUrl).origin;
			if (origin !== expectedOrigin || data.eventType !== 'SUBMIT_1071_DEMOGRAPHIC_FORM') {
				return;
			}

			this.hideDrawer();
			if (data.success) {
				this.directStore.dispatch.DemographicForm.loadFormsStatus({ loanApplicationId: this.applicationId });
			}
		},
		async openDemographicForm(customerId?: number, proposedLoanId?: number) {
			if (!customerId || !proposedLoanId) {
				return;
			}
			// if the url is already fetched then simply open the drawer
			if (this.demographicFormUrl) {
				this.openDrawer();
				return;
			}

			// If we are already loading the url or don't have the details to fetch the url, then ignore this action
			if (this.loadingDemographicFormUrl) {
				return;
			}

			try {
				this.loadingDemographicFormUrl = true;

				// fetch the demographic form url
				const service = AutoFactory.get(AnonymousBorrowerMinimumAccessService);
				const fetchedUrl = await service.create1071DemographicForm1071Url(customerId, proposedLoanId);

				// Format it since we need to inform it is in a nested window
				this.demographicFormUrl = formatUrl(fetchedUrl, this.origin);

				this.openDrawer();
			} finally {
				this.loadingDemographicFormUrl = false;
			}
		},
		async emailSent() {
			await this.directStore.dispatch.DemographicForm.loadFormLogs({ loanApplicationId: this.applicationId });
		},
		formIsCompleted(customerId: number): boolean {
			return this.demographicFormStatus[customerId] === 'completed';
		}
	}
});
