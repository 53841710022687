
import Vue, { PropType } from 'vue';
import uniq from 'lodash/uniq';
import { RouteMixin } from '@/mixins';
import { ApplicationNavigationUtils } from '@/utils';
import { LoanApplication, ApplicationTemplateMetadata } from '@sageworks/jpi';
import { getProposedProductName } from '@/utils/proposed-products/proposed-product-helper';
import { OnlinePortalRoutes } from '@/OnlinePortalRoutes';
import { RemoveLoanModal } from '@/components/remove-loan-modal';
import { NewLoanRequestModal } from '@/components/new-loan-request-modal';
import { LoanApplicationMetadataModuleState } from '../../store/LoanApplicationMetadataModule';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { UsableProducts } from '@sageworks/jpi';

export default Vue.extend({
	name: 'ApplicationWizardNavigation',
	components: { RemoveLoanModal, NewLoanRequestModal, FontAwesomeIcon },
	mixins: [RouteMixin],
	props: {
		applicationId: {
			type: Number,
			required: false,
			default: 0
		},
		navigationMetadata: {
			type: Array as PropType<{ id: string; label: string }[]>,
			required: false,
			default: () => {
				return [];
			}
		},
		pageIndex: {
			type: Number,
			required: true
		}
	},
	data() {
		return {
			subNavItemCssClass: 'pl-5 font-weight-normal dynamic-forms-wizard-nav-item dynamic-forms-wizard-active-product',
			baseNavItemCssClass: 'dynamic-forms-wizard-nav-item',
			activeNavItemCssClass: 'dynamic-forms-wizard-active-page'
		};
	},
	computed: {
		activeRouteName(): string {
			return this.$route.name ?? '';
		},
		metadataList(): ApplicationTemplateMetadata[] {
			return this.directStore.state.LoanApplicationMetadata.metadata;
		},
		currentLoanIndex(): number {
			return this.directStore.state.MultiLoanApplicationForm.loanIndex;
		},
		activeMetadata(): ApplicationTemplateMetadata | undefined {
			const { metadata } = this.directStore.state.LoanApplicationMetadata as LoanApplicationMetadataModuleState;
			return metadata[this.currentLoanIndex];
		},
		isApplicationRoute(): boolean {
			return OnlinePortalRoutes.ApplicationForm.name === this.activeRouteName;
		},
		allowBorrowersToAddRemoveLoans(): boolean {
			return (
				(this.directStore.getters.User.isLender || this.directStore.getters.InstitutionSettings.allowBorrowerToAddRemoveLoans) &&
				this.applicationType !== LoanApplication.TypeEnum.Sba
			);
		},
		applicationType(): LoanApplication.TypeEnum | null {
			return this.directStore.state.LoanApplicationData.loanApplication?.type ?? null;
		},
		canAddNewLoan() {
			return this.allowBorrowersToAddRemoveLoans && this.applicationType && this.directStore.state.LoanApplicationMetadata.metadata.length < 5;
		},
		formattedProposedProducts() {
			const validationResults = this.directStore.state.LoanApplicationData.validationResults?.results ?? {};

			return this.directStore.state.LoanApplicationMetadata.metadata
				.filter(x => x.proposedProduct && x.loanMappingId)
				.map(metadata => ({
					name: this.getProductName(metadata.proposedProduct!),
					loanMappingId: metadata.loanMappingId!,
					isValid: validationResults[metadata.loanMappingId!]?.isValid ?? true
				}));
		},
		activeProductPages(): { id: string; label: string; isValid: boolean; errors: string[] }[] {
			const validationResult = this.directStore.state.LoanApplicationData.validationResults?.results ?? {};
			const pages = this.navigationMetadata;

			if (!this.activeMetadata) return [];

			const { isValid, errors } = validationResult[this.activeMetadata.loanMappingId] ?? { isValid: true, errors: [] };

			return pages.map(x => {
				const pageErrors = errors?.filter(({ componentPath }) => componentPath && componentPath.length > 0 && componentPath[0] === x.id) ?? [];

				return {
					id: x.id,
					label: x.label,
					isValid: isValid ? true : pageErrors.length === 0,
					errors: uniq(pageErrors.map(error => error.message) as string[])
				};
			});
		},
		itemLabel(): string {
			if (this.hasCommunityLendingSubscription === undefined) {
				return '';
			}
			return this.hasCommunityLendingSubscription ? 'Request Form' : 'Application';
		},
		hasCommunityLendingSubscription(): boolean | undefined {
			return this.directStore.state.UsableProducts.usableProducts?.has('abrigoCommunityLending' as UsableProducts.ProductsEnum);
		}
	},
	async mounted() {
		await this.directStore.dispatch.UsableProducts.fetchUsableProducts();
	},
	methods: {
		getProductName: getProposedProductName,
		getNavItemClass(routeName: string): string {
			let output = this.baseNavItemCssClass;
			if (routeName === this.activeRouteName) {
				output += ' ' + this.activeNavItemCssClass;
			}
			return output;
		},
		getProductClass(loanMappingId: number): string {
			let output = this.baseNavItemCssClass;
			if (loanMappingId === this.activeMetadata?.loanMappingId && this.isApplicationRoute) {
				output += ' dynamic-forms-wizard-active-product dynamic-forms-wizard-active-product-name';
			}
			return output;
		},
		getSubmitButtonName(): string {
			return OnlinePortalRoutes.ApplicationSubmit.name;
		},
		async navigateToApplication(loanMappingId: number): Promise<void> {
			const { metadata } = this.directStore.state.LoanApplicationMetadata as LoanApplicationMetadataModuleState;
			const loanIndex = metadata.findIndex(x => x.loanMappingId === loanMappingId);
			const newIndex = loanIndex !== this.currentLoanIndex;
			if (newIndex) {
				this.$root.$emit('change-loan', loanIndex);
			}
			if (!this.isApplicationRoute) {
				await this.directStore.dispatch.MultiLoanApplicationForm.updateLoanIndex({ loanIndex: loanIndex });
				ApplicationNavigationUtils.navigateToApplicationForm(this.$router, this.applicationId);
			}
		},
		navigateToDocumentation(): void {
			ApplicationNavigationUtils.navigateToApplicationDocumentation(this.$router, this.applicationId);
		},
		navigateToSubmit(): void {
			ApplicationNavigationUtils.navigateToApplicationSubmit(this.$router, this.applicationId);
		},
		navigateToPage(index: Number, isValid: boolean): void {
			this.$root.$emit('navigate-to-page', { index: index, isValid: isValid, previousPageId: this.activeProductPages[this.pageIndex].id });
		}
	}
});
